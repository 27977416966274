.manage_container {
    padding: 2%;

}
.services_container h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4e4e4e;
    font-weight: 600;
    margin-bottom:2rem;
    padding: 2%;
}
/* .services_container h2 span {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: rgb(0, 189, 0);
    color: #fff;
    font-size: 1.3rem;
  
    justify-content: center;
} */
.list_item  {
    display: flex;
    font-size: 1.3rem;
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    padding: 0.2rem;
    margin: 1% 2%;
    justify-content: space-between;
    align-items: center;
}
.list_item p {
    font-size: 1.3rem;

}
.list_action_container {
    display: flex;
    gap: 10px;
    align-items: center;
}
.list_action_container p {
    display: flex;
    align-items: center;

}
@media (max-width:768px) {
    .manage_container {
        
    }
}