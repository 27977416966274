.package_details_container{
    padding: 2%;
    display: grid;
		grid-template-columns: 45% 54%;
		gap: 1%;
}
.package_image {
    width: 30vw;
    margin-top: 2rem;
}
.left p {
    margin-top: 5px;
}
.left li {
    font-size: 1.2rem;
    font-weight: 400;
    margin-left: 5rem;
    margin-top: 5px;
   
}