.package_container {
    
   
    min-height: 100vh;

}
.dropdown_flex {
    display: flex;
    gap: 2rem;
    align-items: center;
   
    width: 100%;
    justify-content: end;
    padding-right: 5vh;
    margin-top: 5vh;
}

.package_grid_container {
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(25%, 48%));
    grid-template-columns: repeat(auto-fit, 186px);
    grid-template-columns: repeat(auto-fill, minmax(48%, 48%)); */
    grid-template-columns: repeat(auto-fill, min(40%, 280px));
    gap: 0.5%;
    margin-top: 2rem;
    padding-bottom: 2rem;
    flex-wrap: wrap;
    gap: 1rem;
    row-gap: 4rem;
    justify-content: space-evenly;
    /* align-items: flex-start; */
    width: 100%;
}
@media (max-width: 768px) {
    .package_grid_container {
        /* margin-top: 2rem; */
        grid-template-columns: repeat(auto-fill, min(48%, 220px));
    }

}