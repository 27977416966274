.login_container {
    height: 100vh;
    display: flex;
   align-items: center;
    flex-direction: column;
    gap: 1rem;
    
}
.login_container h1 {
    margin-top: 10%;
    color: #333333;
}
.login_container input {
    font-size: 1rem;
    width: 20rem;
}
.login_inner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  
    padding: 2%;

}