.package_item {
    /* width: 100%; */
    position: relative;
    /* 	min-height: 350px; */
    /* 	border-radius: 10px; */
    /* max-width: 70rem; */
    /* 	background: red; */
    display: flex;
    /* 	background: red; */
    /* align-items: flex-start; */
    /* 	flex-wrap: wrap; */
    gap: 0.5rem;
    /* 	padding-bottom: 2rem; */
    /* border: 1px solid red; */
    /* 	justify-content: center; */

    flex-direction: column;
    /* 	box-shadow: 0px 3px 15px -5px rgba(87, 87, 87, 0.25); */
    background: rgba(241, 241, 241, 0.363);
    border-bottom: 1px solid rgb(219, 219, 219);
}
.package_item h3 {
    font-weight: 500;
    text-align: start;
    font-size: var(--text-medium);
    color: #333;
    /* 	white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
}
.package_item h2 {
    font-weight: 600;
    text-align: center;
    font-size: var(--header-small);
    color: var(--tertiary);
}
.package_item h2 span {
    font-size: var(--text-medium);
    font-weight: 500;
}
.package_item p {
    color: #333;
    z-index: 11;

    font-size: var(--text-small);
    /* 	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}
.package_points span {
    display: flex;

    justify-content: center;
    /* display: none; */
    /* background: rebeccapurple; */
    /* height: max-content;
    margin-top: 0.5rem; */
    margin-top: 2px;
}

.package_points {
    display: flex;
    margin-top: 1rem;
    /* 	white-space: nowrap;
    overflow: hidden;
    width: 100%;
    
    text-overflow: ellipsis; */
    /* align-items: center; */
    align-items: flex-start;
    gap: 0.2rem;
    /* 	width: min(80%, 250px); */
}
.package_item_img {
    /* width: 15rem; */
    /* 	height: 15rem; */
    /* 		border-radius: 10px; */
    /* height: 15rem; */
    /* 	z-index: -1; */
    height: 15rem;
}
.package_item_img img {
    height: 100%;
    width: 100%;
}
.package_card_details_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem;
    padding-top: 0rem;
}
.addons_text {
    padding: 0.5rem;
}
.book_btn_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    gap: 1rem;
    flex-direction: column;
    /* background: red; */
    padding: 0.5rem;
    padding-bottom: 0.5rem;
}
.book_btn {
    all: unset;
    text-align: center;
    /* 	width: 10rem; */
    /* padding: 0.5rem 0rem; */
    background: #d8aa00;
    background: linear-gradient(90deg, #eac036 22.8%, #fff02c 108.78%);
    cursor: pointer;
    font-size: var(--text-large);
    font-weight: 300;
    width: 100%;
    height: 3.5rem;
    color: #333;
}
.cart_add_btn {
    all: unset;
    display: flex;
    justify-content: space-evenly;
    padding: 0rem 0rem;
    background: #d8aa00;
    background: linear-gradient(90deg, #eac036 22.8%, #fff02c 108.78%);
    font-size: var(--text-large);
    font-weight: 300;
    width: 100%;
    height: 3.5rem;
    align-items: center;
}
.cart_action_icon {
    width: 3rem;
    height: 100%;
    cursor: pointer;
    background: #2585d3;
    display: flex;
    align-items: center;

    justify-content: center;
    /* 	border-radius: 50%; */
    color: #fff;
    font-size: var(--text-large);
}
.rating_text {
    background: #83be34;
    color: #fff;
    padding: 2px;
    margin-left: 100px;
}
.rating_container {
    position: relative;
    align-self: flex-end;
    display: flex;
    align-items: center;
}
.default_star {
    position: absolute;
    display: flex;
}
/* 	.package_item::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    background: linear-gradient(
        to bottom,
        rgba(245, 246, 252, 0.12) 45%,
        rgba(15, 15, 15, 0.73) 100%
    );
} */
.package_card_details_container a:hover {
    text-decoration: underline;
}
a {
    text-decoration: none;
}
.loading_container {
    /* 	height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: red; */
    height: 2.5rem;
    width: 2.5rem;
}
.text_loading_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    /* width: 2.5rem; */
}
@media (max-width: 768px) {
    .package_item {
        padding-bottom: 0;
        box-shadow: none;
        background: #fff;
    }
    .package_item p {
        font-size: var(--text-small);
    }
}