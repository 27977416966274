:root {
  --header-large: 3rem;
--header-medium: 2.5rem;
--header-small: 2rem;
--text-large: 1.7rem;
--text-medium: 1.4rem;
--text-small: 1.2rem;
--primary : #FBD444;
--secondary : #374C54;
--tertiary : #2874f0;
--border : #b1b1b150

}
*{
margin: 0%;
padding: 0%;
box-sizing: border-box;
font-family: 'Nunito Sans', sans-serif;
}

img {
	height: 100%;
	width: 100%;
}
html {

	font-family: 'Nunito Sans', sans-serif;
	scroll-behavior: smooth;
	overflow-x: hidden;

}
h1,h2,h3,h4,h5,h6,p,span {
	color: #333333;
}


p {
	font-size: var(--text-medium);
	margin: 0;

}
a {
	font-size: var(--text-medium);
	margin: 0;
}

/* 
  html {
	-webkit-tap-highlight-color:transparent;
}  
 
  @media  (max-width: 768px) {
	
 html {
		-webkit-tap-highlight-color:transparent;
	}  
      
  }  */

  .DrawerRight {
	width: 80vw;
	margin-left: 20vw;
	
  }
  html {
    font-size: 85.5%;

}
/* @media (min-width: 1800px) {
	html {
		font-size: 75%;
	}  
  }	


  @media (max-width: 1200px) {
	html {
		font-size: 76.25%; 
	}  
     
  } */
  @media (max-width:768px) {
	.DrawerRight {
		width: 100%;
		margin-left: 0;
		margin-top: 10vh;
	
	}
  }