.side_panel_container {
    background: #1976d2;
    width: 20vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;

   
}
.side_panel_container h1 {
    font-size: 2rem;
    color: #fff;
    text-align: center;
}
.side_panel_container p {
    color: #fff;
    cursor: pointer;
}
.burger_menu {
    display: none;
}
.close_icon {
    display: none;
}

.side_panel_container p {
    padding: 1%;
    border-bottom: 1px solid #000;
}
@media (max-width:768px) {
    .side_panel_container {
       
        transform: translateX(-100vw);
        transition: all 0.3s ease;
    }
    .burger_menu {
        display: flex;
        flex-direction: column;
        gap: 7px;
        position: fixed;
        left: 0%;
        top: 0%;
        background: #fff;
        width: 100%;
        padding: 2%;
       
    }
    .side_panel_container.open {
        width: 100vw;
        transform: translateX(0);
    }
    .burger_menu span {
        display: inline-block;
        width: 7vh;
        height: 3px;
        
        background: #1976d2;
     
    }
    .burger_menu input {
        display: none;
    }
    .close_icon {
        display: block;
    }
}