.form_inner {
    display: flex;
    flex-direction: column;
    width: 80%;
}
.file_upload input {
    display: none;
}
.file_upload {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 2%;
    border: 1px dashed #1976d2;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    width: max-content;
    max-width: 100%;
   

}
.file_upload span {
    width: 90%;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.file_upload_image {
    width: 10rem;
    height: 10rem;
    margin: auto;
}