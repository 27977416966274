.j_dropdown_container {
    width: max-content;

    /* 		width: 30rem; */
    width: 100%;
    position: relative;

    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}
.disabled {
    background: #cfd9e02f !important;
}
.j_dropdown_selected.disabled p span:nth-child(1) {
    color: #707070;
}
.j_dropdown_selected {
    width: 100%;
    text-align: center;
    border-radius: 4px;
    display: flex;
    /* 	background: #f7f6ec; */
    background: #fff;
    border: 1px solid #b1b1b1;
    height: max-content;
    justify-content: center;
    padding: 1%;
}
.j_dropdown_selected p {
    font-size: var(--text-medium);
    text-align: start;
    width: 100%;
    display: flex;
    color: #333333;
    align-items: center;

    justify-content: space-between;
    white-space: nowrap;
    gap: 1rem;
}
.j_dropdown_selected p span:nth-child(1) {
    width: 85%;
    overflow-x: hidden;
    color: #333333;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.j_dropdown_selected .icon {
    position: absolute;
    right: 5%;

    cursor: pointer;
    transition: transform 0.3s ease;
}
.icon.iconactive {
    transform: rotate(-225deg);
}
.j_dropdown_contents {
    background: #fff;
    width: 100%;
    box-shadow: 0px 5px 20px 2px rgba(104, 113, 99, 0.25);
    position: absolute;
    z-index: 23;
    overflow-x: hidden;
    max-height: 200px;
    width: 100%;
    overflow-y: auto;
    margin-top: 1rem;
    /* padding: 3%; */
}
.j_dropdown_contents p {
    cursor: pointer;
    padding: 1%;
    padding-left: 3%;
    color: #707070;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    /* padding: 3%; */
}
.j_dropdown_contents p:hover {
    color: #fff;
    background: #6b797857;
}